import React from 'react';
import App from 'next/app';

import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { ThemeProvider } from 'styled-components';
import 'antd/dist/antd.less';
import cookie from 'cookie';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import { hotjar } from 'react-hotjar';
import Router from 'next/router';
import TagManager from 'react-gtm-module';
import withApollo from '../lib/apollo/withApollo';
import getUserLayerInfo from '../lib/utils';
import { addCommonScript } from '../lib/addNaverAdLogScript';
// import { addCommonDaumScript } from '../lib/addDaumAdLogScript';

import { THEME } from '../constants';

import GlobalStyle from '../components/GlobalStyle';

import * as AuthQueries from '../views/auth/queries';

import LoadingIndicator from '../components/Skeleton/index';
import chat from './chat';

import { injectRetargetTrackingScript, injectRealClickTrackingScript } from '../lib/addRealClickScript';

Sentry.init({
  dsn: process.env.SENTRY_REACT_URI,
  environment: process.env.STAGE
});

// IE version check func

class MyApp extends App {
  state = {
    history: []
  };

  static async getInitialProps({ Component, ctx }) {
    // getting token
    let token = null;
    if (ctx.req) {
      // on server side
      // eslint-disable-next-line prefer-destructuring
      token = cookie.parse(ctx.req.headers.cookie ? ctx.req.headers.cookie : '').token;
    } else {
      // on route change
      token = Cookies.get('token');
    }

    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps, token };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  componentDidMount() {
    addCommonScript(); // 네이버 광고 로그분석 공통 스크립트
    // addCommonDaumScript(); // 다음 광고 로그분석 공통 스크립트

    // 리얼클릭 스크립트 추가
    injectRetargetTrackingScript();
    injectRealClickTrackingScript();

    IMP.init('imp80907496'); // 아임포트 가맹점 식별코드

    if (process.env.CHANNEL_IO_KEY) {
      chat(process.env.CHANNEL_IO_KEY);
    }
    if (process.env.HOTJAR_ID) {
      hotjar.initialize(process.env.HOTJAR_ID, 6);
    }

    if (process.env.GTM_ID) {
      const client = this.props.apolloClient;
      const { auth } = client.readQuery({
        query: AuthQueries.AUTH
      });
      getUserLayerInfo(client, !get(auth, 'isLoggedIn')).then(userLayerInfo => {
        TagManager.initialize({
          dataLayer: userLayerInfo,
          gtmId: process.env.GTM_ID
        });
      });
    }
    // 클라이언트 쿼리 해서 GTM 유저 불러오기,
    // 클라이언트 쿼리해서 로컬 유저 불러오기

    // 페이스북 유입 분석 코드
    import('react-facebook-pixel')
      .then(x => x.default)
      .then(ReactPixel => {
        // ReactPixel.init('468185553958250');
        ReactPixel.init('1244797116689020');
        ReactPixel.pageView();

        Router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });
  }

  constructor(props) {
    super(props);
    const { apolloClient, token } = props;

    // setting user existence on state's isLoggedIn
    const { auth } = apolloClient.readQuery({
      query: AuthQueries.AUTH
    });
    apolloClient.writeQuery({
      query: AuthQueries.AUTH,
      data: {
        auth: {
          ...auth,
          isLoggedIn: Boolean(token)
        }
      }
    });
  }

  render() {
    const { Component, pageProps, apolloClient } = this.props;
    return (
      <ApolloProvider client={apolloClient}>
        <ApolloHooksProvider client={apolloClient}>
          <ThemeProvider theme={THEME}>
            <>
              <GlobalStyle />
              <Component {...pageProps} {...LoadingIndicator} />
            </>
          </ThemeProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    );
  }
}

export default withApollo(MyApp);
